<template>
  <a-modal
      :width="1000"
      v-model="showPopup"
      :title="titleText"
      @cancel="resetForms"
      @ok="handleConfirm"
  >
    <div class="content-popup">
      <a-form-model :model="params" v-bind="layout">

     <!--   搜索大币种    -->
        <a-form-item label="选择大币种">
          <!-- 选择大币种 -->
          <a-select
              :dropdownMatchSelectWidth="false"
              @change="handleChangeBigCoin"
              @search="coinSearch"
              :filter-option="untils.filterOption"
              allowClear
              showSearch
              class="w-300 ml-5"
              :disabled="pid !== 0 && !!editorId"
              v-model="searchVersionParams.coinId"
              placeholder="请选择大币种"
          >
            <a-select-option
                v-for="item of coinList"
                :key="item.id"
                :value="item.id"
              
            >{{ item.coinKindName + (item.coinCategory ? `---${item.coinCategory}` : '' ) }}</a-select-option>
          </a-select>
        </a-form-item>

      <!--   一级分类名称    -->
        <a-form-item label="选择一级分类名称" v-if="!editorId">
          <a-select
              :dropdownMatchSelectWidth="false"
              @search="coinSearch"
              :filter-option="untils.filterOption"
              allowClear
              showSearch
              class="w-300 ml-5"
              v-model="searchVersionParams.id"
              placeholder="选择一级分类名称"
          >
            <a-select-option
                v-for="item of classifyList"
                :key="item.id"
                :value="item.id"
            >{{ item.classfyName }}</a-select-option>
          </a-select>
        </a-form-item>

<!--    编辑二级分类的时候回显一级分类名称    -->
        <a-form-item label="一级分类名称" v-if="editorId">
          <span v-if="params.parentCategoryVo">{{ params.parentCategoryVo.classfyName }}</span>
        </a-form-item>


        <!--    填写二级分类名称    -->
        <a-form-item label="填写二级分类名称">
          <a-input
              allowClear
              v-model="params.classfyName"
              class="w-300 ml-5"
              placeholder='填写二级分类名称'
          ></a-input>
        </a-form-item>

   

<!--    已经选择    -->
        <a-form-item v-if="selectedVersionList && selectedVersionList.length" label='已选择' prop='content'>
          <template  v-for="item in selectedVersionList">
            <a-tag
                color="blue"
                closable
                :key="item.coinKindId"
                @close="handleDeleteTag($event, item)"
            >{{ item.itemName + '-' + item.versionName }}</a-tag>
          </template>
        </a-form-item>
<!--    请搜索选择小币种    -->
        <a-form-item label="请搜索选择小币种" v-if="searchVersionParams.coinId">
          <!-- 请搜索选择小币种 -->
          <a-select
              :dropdownMatchSelectWidth="false"
              @change="handleChangeSmallCoin($event, 'small')"
              @search="searchSmallCoinList"
              :filter-option="untils.filterOption"
              allowClear
              showSearch
              class="w-300 ml-5"
              v-model="searchVersionParams.coinItemId"
              placeholder="请搜索选择小币种"
          >
            <a-select-option
                v-for="item of smallCoinList"
                :key="item.id"
                :value="item.id"
            >{{ item.coinKindItemName }}</a-select-option>
          </a-select>
        </a-form-item>
<!--    版别列表选择    -->
        <a-form-item label="版别选择">
          <a-select
              :dropdownMatchSelectWidth="false"
              @change="handleChangeVersion"
              @search="getVersionList"
              :filter-option="untils.filterOption"
              allowClear
              showSearch
              mode="multiple"
              class="w-100-w ml-5"
              v-model="searchVersionIds"
              placeholder="请搜索选择版别"
          >
            <a-select-option
                v-for="item of searchVersionList"
                :key="item.id"
                :value="item.id"
            >{{ item.kindItemName + '-' + item.coinKindVersionName }}</a-select-option>
          </a-select>
        </a-form-item>
<!--    分类排序   -->
        <a-form-item label="分类排序">
          <a-input
              allowClear
              type="number"
              v-model="params.classfySort"
              class="w-300 ml-5"
              placeholder='分类排序'
          ></a-input>
        </a-form-item>
<!--    分类描述    -->
        <a-form-item label="分类描述">
          <a-textarea
              v-model="params.classfyDesc"
              placeholder="分类描述"
              :rows="4" />
        </a-form-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import untils from "@/untils";
import {
  addOrEditorTwoClassifyVersionByCoinBook, getSmallCoinListByBigCoin,
  getVersionListApi, getOneClassfyList
} from "@/views/cmsPage/coinBookManage/classifyVersion/_apis"
import _bigOrSmallCoinByVersion from "@/_mixins/_bigOrSmallCoinByVersion"

export default {
  mixins: [_bigOrSmallCoinByVersion],
  components: {},
  data() {
    return {
      untils: untils,
      coinList: [],
      classifyList:[],
      smallCoinList: [],
      titleText: '新增二级分类',
      showPopup: false,
      layout: {
        labelCol: { span: 3 }, //标签占5份  共24份
        wrapperCol: { span: 21 },
      },
      params: {
        id: undefined,
        pid:undefined,
        coinId: undefined,
        classfyDesc: undefined,
        classfyName: undefined,
        classfySort: 1,
        coinItemVersionList: undefined,
      },
      // 搜索版别列表list
      searchVersionList: [],
      // 多选的versionId
      searchVersionIds: [],
      // 搜索版别列表-参数
      searchVersionParams: {
        pageNum: 1,
        pageSize: 50,
        id:undefined,
        coinId: undefined,
        coinItemId: undefined,
        versionName: undefined
      },
      oneParams: {
        coinId: undefined
      },
      // 已经选择的版别
      selectedVersionList: [],
      editorId: undefined,
      pid:undefined
    };
  },
  mounted() {},
  methods: {
    async show(data) {
      if (data && data.id) {
        this.$loading.show()
        this.editorId = data.id
        this.titleText = '编辑二级分类'
        await this.coinSearch(data.coinName)
        this.searchVersionParams.coinId = data.coinId
        this.searchVersionParams.pid = data.pid
        await this.setData(data)
      }
      this.$loading.hide()
      this.showPopup = true
    },
    /** 编辑时设置默认回显 */
    async setData(data) {
      Object.assign(this.params, data)
      await this.getVersionList(undefined)
      this.selectedVersionList = data.coinItemVersionList
      this.params.coinItemVersionList = undefined
      this.pid = data.pid
      this.params.classfyName=data.classfyName
      delete this.params.classfySize
      delete this.params.coinName
    },
    /** 编辑的时候删除已经选择的 */
    /** 删除已经选择的 */
    handleDeleteTag(e, item) {
      e.preventDefault();
      const foundIndex = this.selectedVersionList.findIndex(el => el.id === item.id)
      this.selectedVersionList.splice(foundIndex, 1)
    },
    /** 确定添加 */
    async handleConfirm() {
      if (!this.params.classfyName) return this.$message.warn('分类名称不能为空')
      // if (!this.params.classfySort) return this.$message.warn('分类排序不能为空')
      if (!this.searchVersionParams.coinId) return this.$message.warn('大币种必须选择')
      this.params.coinId = this.searchVersionParams.coinId
      this.params.pid = this.editorId ? this.searchVersionParams.pid : this.searchVersionParams.id
      if (!this.searchVersionIds.length && !this.editorId) return this.$message.warn('请添加分类版别')

      if (this.editorId) {
        const tempArr = this.selectedVersionList.map(el => ({id: el.id})) || []
        const tempArrCoin = this.searchVersionIds.map(el => ({id: el})) || []
        const allTempArr = tempArr.concat(tempArrCoin)
        this.params.coinItemVersionList = allTempArr.filter((item, index, self) => index === self.findIndex((t) => (t.id === item.id)));
      } else {
        this.params.coinItemVersionList = this.searchVersionIds.map(el => ({id: el}))
      }
      console.log(this.params)
      const res = await addOrEditorTwoClassifyVersionByCoinBook(this.params)
      if (res.status !== '200') return
      this.$message.success('成功了');
      this.resetForms()
      this.$emit("success");
    },
    /** 重置内容 */
    resetForms() {
      this.params = {
        id: undefined,
        coinId: undefined,
        classfyDesc: undefined,
        classfyName: undefined,
        classfySort: undefined,
        coinItemVersionList: undefined,
      }
      this.searchVersionParams = {
        pageNum: 1,
        pageSize: 50,
        coinId: undefined,
        versionName: undefined,
      }
      this.searchVersionList = []
      this.searchVersionIds = []

      this.showPopup = false
    },
    /** 搜索版别内容 */
    async getVersionList(e, type) {
      this.searchVersionParams.versionName = e
      const res = await getVersionListApi(this.searchVersionParams)
      if (res.status !== '200') return
      const { records } = res.data;
      this.searchVersionList = records;
      if (e || type === "small") {
        this.searchVersionIds = this.searchVersionList.map(el => el.id)
      }
    },
    /** 选择大币种 */
    async handleChangeBigCoin() {
      this.oneParams.coinId = this.searchVersionParams.coinId
      const res = await getOneClassfyList(this.oneParams)
      if (res.status !== '200') return this.classifyList = [];
      this.classifyList = res.data
    },
    /** 选择小种 */
    async handleChangeSmallCoin(e, type) {
      await this.getVersionList(undefined, type)
    },
    async handleChangeVersion(e) {
      console.log(e, '选择版别')
    },
    /** 搜索小币种 */
    async searchSmallCoinList(text) {
       const res = await getSmallCoinListByBigCoin({
         coinId: this.searchVersionParams.coinId,
         coinItemName: text,
       })
      if (res.status !== '200') return;
      this.smallCoinList = res.data.records;
    },
  },
};
</script>

<style lang="scss" scoped>
.data-input {
  span {
    font-size: 15px;
    font-weight: bold;
  }
}
</style>
