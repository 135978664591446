var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 1000, title: _vm.titleText },
      on: { cancel: _vm.resetForms, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "div",
        { staticClass: "content-popup" },
        [
          _c(
            "a-form-model",
            _vm._b(
              { attrs: { model: _vm.params } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-item",
                { attrs: { label: "选择大币种" } },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "w-300 ml-5",
                      attrs: {
                        dropdownMatchSelectWidth: false,
                        "filter-option": _vm.untils.filterOption,
                        allowClear: "",
                        showSearch: "",
                        disabled: !_vm.hasLevelTwoTabStatus && !!_vm.editorId,
                        placeholder: "请选择大币种"
                      },
                      on: {
                        change: _vm.handleChangeBigCoin,
                        search: _vm.coinSearch
                      },
                      model: {
                        value: _vm.searchVersionParams.coinId,
                        callback: function($$v) {
                          _vm.$set(_vm.searchVersionParams, "coinId", $$v)
                        },
                        expression: "searchVersionParams.coinId"
                      }
                    },
                    _vm._l(_vm.coinList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [
                          _vm._v(
                            _vm._s(
                              item.coinKindName +
                                (item.coinCategory
                                  ? "---" + item.coinCategory
                                  : "")
                            )
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "填写分类名称" } },
                [
                  _c("a-input", {
                    staticClass: "w-300 ml-5",
                    attrs: { allowClear: "", placeholder: "分类名称" },
                    model: {
                      value: _vm.params.classfyName,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "classfyName", $$v)
                      },
                      expression: "params.classfyName"
                    }
                  })
                ],
                1
              ),
              _vm.selectedVersionList && _vm.selectedVersionList.length
                ? _c(
                    "a-form-item",
                    { attrs: { label: "已选择", prop: "content" } },
                    [
                      _vm._l(_vm.selectedVersionList, function(item) {
                        return [
                          _c(
                            "a-tag",
                            {
                              key: item.coinKindId,
                              attrs: { color: "blue", closable: "" },
                              on: {
                                close: function($event) {
                                  return _vm.handleDeleteTag($event, item)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(item.itemName + "-" + item.versionName)
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _vm.searchVersionParams.coinId
                ? _c(
                    "a-form-item",
                    { attrs: { label: "选择小币种" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticClass: "w-300 ml-5",
                          attrs: {
                            dropdownMatchSelectWidth: false,
                            "filter-option": _vm.untils.filterOption,
                            allowClear: "",
                            showSearch: "",
                            disabled:
                              _vm.hasLevelTwoTabStatus && !!_vm.editorId,
                            placeholder: "请搜索选择小币种"
                          },
                          on: {
                            change: function($event) {
                              return _vm.handleChangeSmallCoin($event, "small")
                            },
                            search: _vm.searchSmallCoinList
                          },
                          model: {
                            value: _vm.searchVersionParams.coinItemId,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.searchVersionParams,
                                "coinItemId",
                                $$v
                              )
                            },
                            expression: "searchVersionParams.coinItemId"
                          }
                        },
                        _vm._l(_vm.smallCoinList, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.id, attrs: { value: item.id } },
                            [_vm._v(_vm._s(item.coinKindItemName))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.searchVersionParams.coinId
                ? _c(
                    "a-form-item",
                    { attrs: { label: "版别选择" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticClass: "w-100-w ml-5",
                          attrs: {
                            dropdownMatchSelectWidth: false,
                            "filter-option": _vm.untils.filterOption,
                            allowClear: "",
                            showSearch: "",
                            mode: "multiple",
                            disabled:
                              _vm.hasLevelTwoTabStatus && !!_vm.editorId,
                            placeholder: "请搜索选择版别"
                          },
                          on: {
                            change: _vm.handleChangeVersion,
                            search: _vm.getVersionList
                          },
                          model: {
                            value: _vm.searchVersionIds,
                            callback: function($$v) {
                              _vm.searchVersionIds = $$v
                            },
                            expression: "searchVersionIds"
                          }
                        },
                        _vm._l(_vm.searchVersionList, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.id, attrs: { value: item.id } },
                            [
                              _vm._v(
                                _vm._s(
                                  item.kindItemName +
                                    "-" +
                                    item.coinKindVersionName
                                )
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-item",
                { attrs: { label: "分类排序" } },
                [
                  _c("a-input", {
                    staticClass: "w-300 ml-5",
                    attrs: {
                      allowClear: "",
                      type: "number",
                      placeholder: "分类排序"
                    },
                    model: {
                      value: _vm.params.classfySort,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "classfySort", $$v)
                      },
                      expression: "params.classfySort"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "分类描述" } },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "分类描述", rows: 4 },
                    model: {
                      value: _vm.params.classfyDesc,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "classfyDesc", $$v)
                      },
                      expression: "params.classfyDesc"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }